@import "../sass/abstracts/variables";

.main-container {
    display: grid;
    grid-template-columns: 70% 30%;
    padding: 0rem 10rem;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 2rem;
    }
    @media screen and (max-width: 820px) {
        grid-template-columns: 1fr;
        padding: 2rem;
    }
}

.section-aboutme {
    background-color: $color-white;
    margin-top: 10rem;
    margin-left: 15rem;
    margin-right: 3rem;
    @media screen and (max-width: 768px) {
        margin: 0;
    }
}

.heading-secondary {
    color: $color-gray;
    background-color: $color-white;
    text-transform: uppercase;
    font-size: 4rem;
}

.image {
    background-image: url("../img/profile.jpg");
    background-size: cover;
    background-position: center;
    height: 60%;
    border-radius: 50%;
    padding: 25rem 10rem;
    @media screen and (max-width: 768px) {
        border-radius: 0%;
        height: 60%;
        width: 100%;
        padding: 33rem 10rem;
    }
    @media screen and (max-width: 820px) {
        border-radius: 0%;
        height: 60%;
        width: 100%;
        padding: 33rem 10rem;
    }
    @media screen and (min-width: 821px) and (max-width: 1000px) {
        display: none;
    }
    @media screen and (min-width: 1001px) and (max-width: 1575px) {
        border-radius: 0%;
        height: 60%;
        width: 100%;
        padding: 33rem 10rem;
    }
}

p {
    font-size: 2rem;
    margin-bottom: 3rem;
}
