.header {
    height: 100vh;
    background-image: linear-gradient(to right bottom, $color-primary, $color-primary-sub), url(../../img/hero.jpg);
    background-size: cover;
    background-position: top;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);

    &-text-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
}

.logo-box {
    position: absolute;
    top: 5rem;
    left: 5rem;
    /* Styles for screens with a maximum width of 768 pixels (tablets and smaller) */
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.logo {
    height: 14rem;
    border-radius: 1rem;
}
