.btn {
    &:link,
    &:visited {
        text-transform: uppercase;
        text-decoration: none;
        padding: 1.5rem 4rem;
        display: inline-block;
        border-radius: 10rem;
        transition: all 0.2s;
        position: relative;
    }

    &::hover {
        transform: translateY(-3px);
        box-shadow: 0 1rem 2rem $color-black;
    }

    &:hover::after {
        transform: scaleX(1.4) scaleY(1.6);
        opacity: 0;
    }

    &:active {
        transform: translateY(2px);
        box-shadow: 0 0.5rem 1rem $color-black;
    }

    &:after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 10rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all 0.4s;
    }
    &-white {
        background-color: $color-white;
        color: $color-gray;
    }

    &-white::after {
        background-color: $color-white;
    }

    &-animated {
        animation: moveInBottom 0.5s ease-out 0.75s;
        animation-fill-mode: backwards;
    }

    &::hover {
        transform: translateY(-3px);
        box-shadow: 0 1rem 2rem $color-black;
    }

    &:hover::after {
        transform: scaleX(1.4) scaleY(1.6);
        opacity: 0;
    }

    &:active {
        transform: translateY(2px);
        box-shadow: 0 0.5rem 1rem $color-black;
    }

    &:after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 10rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all 0.4s;
    }
    &-white {
        background-color: $color-white;
        color: $color-gray;
    }

    &-white::after {
        background-color: $color-white;
    }

    &-animated {
        animation: moveInBottom 0.5s ease-out 0.75s;
        animation-fill-mode: backwards;
    }
}